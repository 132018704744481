<!--
* @description:
* @fileName activeEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <div class="contentBox">
    <el-card>
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="formRef">
          <el-form-item label="活动标题" prop="title" :label-width="80">
            <el-input v-model="form.title" autocomplete="off" placeholder="请输入活动标题" disabled></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="activeTime" :label-width="80">
            <el-date-picker v-model="form.activeTime" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" disabled value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
          </el-form-item>
          <!-- 
        <el-form-item label="结束时间" prop="endTime" :label-width="80">
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
          ></el-date-picker>
        </el-form-item> -->
          <el-form-item label="省市选择" prop="areaList" :label-width="80">
            <el-cascader :props="optionsLevel" v-model="form.areaList" disabled v-if="showCasc" style="width: 250px" />
          </el-form-item>
          <el-form-item label="活动地址" prop="address" :label-width="80">
            <el-input v-model="form.address" autocomplete="off" disabled placeholder="请输入活动地址"></el-input>
          </el-form-item>

          <el-form-item label="海报图片" prop="bannerUrl" :label-width="80">
            <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.fileList" :http-request="upBannar" :on-error="handleError" :on-exceed="handleExceed" :on-preview="handlePreview" :on-remove="handleRemove" disabled>
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                <em>点击上传文件</em>
              </div>
              <!-- <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template> -->
            </el-upload>

            <!-- <el-image
            :src="form.bannerUrl"
            :fit="'fill'"
            v-else
            style="width: 360px; height: 180px"
          ></el-image> -->
          </el-form-item>

          <el-form-item label="直播地址" prop="liveUrl" :label-width="80">
            <el-input v-model="form.liveUrl" autocomplete="off" disabled></el-input>
          </el-form-item>

          <el-form-item label="主办方" prop="organisers" :label-width="80">
            <el-input v-model="form.organisers" autocomplete="off" disabled placeholder="请输入主办方名称"></el-input>
          </el-form-item>

          <el-form-item label="活动人数" prop="peopleNum" :label-width="80">
            <el-input v-model="form.peopleNum" placeholder="请输入活动人数" disabled />
          </el-form-item>

          <el-form-item label="活动亮点" prop="sparkle" :label-width="80">
            <el-input type="textarea" v-model="form.sparkle" autocomplete="off" disabled placeholder="请输入活动亮点"></el-input>
          </el-form-item>

          <el-form-item label="活动标签" :label-width="80" prop="activityTagIds">
            <el-select v-model="form.activityTagIds" placeholder="请选择活动标签" disabled multiple>
              <template v-for="(item, index) in tagOption" :key="index">
                <el-option :label="item.label" :value="item.data"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="活动类型" :label-width="80" prop="activityTypeIds">
            <el-select v-model="form.activityTypeIds" multiple disabled placeholder="请选择活动类型">
              <template v-for="(item, index) in typeOption" :key="index">
                <el-option :label="item.label" :value="item.data"></el-option>
              </template>
            </el-select>
          </el-form-item>

          <el-form-item label="活动状态" :label-width="80" prop="status">
            <el-radio-group v-model="form.status" disabled>
              <template v-for="(item, index) in radioData" :key="index">
                <el-radio :label="item.value">{{ item.label }}</el-radio>
              </template>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动简介" :label-width="80" prop="description">
            <vab-quill v-model="form.description" :min-height="400" :options="Richoptions" />
          </el-form-item>

          <!-- <el-form-item align="center">
            <el-button type="primary" @click="submitInfo()">
              保存活动基础信息
            </el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </el-card>

    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          票务管理
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="12">
          <!-- <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
            添加
          </el-button> -->
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-table v-loading="listLoading" border :data="ticketList">
        <el-table-column prop="name" label="名称" />
        <el-table-column fixed label="开始时间" show-overflow-tooltip>
          <template #default="{ row }">
            <span>{{ handleDate(row.orderStartTime) }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed label="结束时间" show-overflow-tooltip>
          <template #default="{ row }">
            <span>{{ handleDate(row.orderEndTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="是否展示">
          <template #default="{ row }">
            <el-tag v-if="row.isShow">展示</el-tag>
            <el-tag type="warning" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" />
        <el-table-column prop="type" label="类型">
          <template #default>
            <el-tag type="success">免费</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="explain" label="描述" />
        <!-- <el-table-column label="操作" show-overflow-tooltip width="185">
          <template #default="{ row }">
            <el-button type="primary" @click="handleEdit(row)">
              <vab-icon icon="edit-2-line" />
              编辑
            </el-button>
            <el-button type="danger" @click="handleDelete(row)">
              <vab-icon icon="delete-bin-6-line" />
              删除
            </el-button>
          </template>
        </el-table-column> -->
        <template #empty>
          <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
    </el-card>
    <!-- <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div> -->

    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          报名信息
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="12">
          <el-button @click="handleDownload" type='success'>导出报表</el-button>
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-table v-loading="listLoading" border :data="signPeopleList">
        <!-- <el-table-column fixed label="证件类型" show-overflow-tooltip>
          <template #default="{ row }">
            <span v-if="row.idType == 1">身份证</span>
            <span v-if="row.idType == 2">港澳居民来往内地通行证</span>
            <span v-if="row.idType == 3">台湾居民来往大陆通行证</span>
            <span v-if="row.idType == 4">外国人永久居住证</span>
            <span v-if="row.idType == 5">护照</span>
            <span v-if="row.idType == 99">其他</span>
          </template>
        </el-table-column>

        <el-table-column fixed label="证件号码" show-overflow-tooltip prop="idNo"></el-table-column> -->
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="type" label="头像">
          <template #default="{ row }">
            <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="row.avatar" fit="contain"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" />
        <el-table-column label="状态">
          <template #default="{ row }">
            <el-tag type="success" v-if="row.status == 'SIGN_UP'">已报名</el-tag>
            <el-tag v-if="row.status == 'PARTICIPATE'">已参加</el-tag>
            <el-tag type="warning" v-if="row.status == 'EXPIRED'">
              已过期
            </el-tag>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
    </el-card>

    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

    <ticket-edit ref="ticketEditRef" @fetch-data="getTicket" :activeCode="form.code" :maxNum="form.peopleNum"></ticket-edit>
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'
import {
  upLoadImg,
  getLabelType,
  getLabelLabel,
  addActive,
  activeDetail,
  editActive,
  getActiveTicket,
  delActiveTicket,
} from '@/api/active'

import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'

import { parseTime } from '@/utils/index'
import VabQuill from '@/extra/VabQuill'
import VabUpload from '@/extra/VabUpload'
export default defineComponent({
  name: 'editActive',
  components: {
    VabQuill,
    VabUpload,
    ticketEdit: defineAsyncComponent(() =>
      import('./components/activeTicketEdit')
    ),
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      dialogVisible: false,
      listLoading: false,
      dialogImageUrl: '',
      formRef: null,
      ticketEditRef: null,
      vabUploadRef: null,
      checkMenu: [],
      ticketList: [],
      signPeopleList: [],
      size: '50%',
      radioData: [
        {
          value: 'ON',
          label: '启用',
        },
        {
          value: 'OFF',
          label: '停用',
        },
        {
          value: 'DRAFT',
          label: '草稿',
        },
      ],
      form: {
        description: '',
        startTime: '',
        endTime: '',
        areaList: [],
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入活动标题' }],
        activeTime: [
          { required: true, trigger: 'blur', message: '请选择起止时间' },
        ],
        type: [
          { required: true, trigger: 'change', message: '请选择标签类型' },
        ],
        areaList: [
          { required: true, trigger: 'change', message: '请选择省市' },
        ],
        address: [
          { required: true, trigger: 'blur', message: '请填写详情地址' },
        ],
        bannerUrl: [
          { required: true, trigger: 'change', message: '请上传海报' },
        ],
        organisers: [
          { required: true, trigger: 'blur', message: '请输入主办方' },
        ],
        peopleNum: [
          { required: true, trigger: 'blur', message: '请输入活动人数' },
        ],
        sparkle: [
          { required: true, trigger: 'blur', message: '请输入活动亮点' },
        ],
        activityTagIds: [
          { required: true, trigger: 'blur', message: '请选择活动标签' },
        ],
        activityTypeIds: [
          { required: true, trigger: 'blur', message: '请选择活动类型' },
        ],
        status: [
          { required: true, trigger: 'change', message: '请选择活动状态' },
        ],
        description: [
          { required: true, trigger: 'blur', message: '请输入活动简介' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
      pickerStartDate: null,
      limit: 1,
      showCasc: false,
      Richoptions: {
        theme: 'snow',
        bounds: document.body,
        debug: 'warn',
        readOnly: true,
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ color: [] }, { background: [] }],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ align: [] }],
              [{ direction: 'rtl' }],
              [{ font: [] }],
              ['clean'],
              ['link', 'image'],
            ],
            handlers: {
              image: () => {
                state['vabUploadRef'].handleShow()
              },
            },
          },
        },
      },
      typeOption: [],
      tagOption: [],
      bookType: 'xlsx',
      options: ['xlsx', 'csv', 'txt'],
      filename: '',
      autoWidth: true,
    })

    const fetchData = async () => {
      const { data: typeList } = await getLabelType()
      const { data: tagList } = await getLabelLabel({}, 'ACTIVITY')
      state.typeOption = typeList
      state.tagOption = tagList
    }

    // 编辑活动获取活动详情
    const getActiveDetail = async (code) => {
      state.showCasc = false
      state.listLoading = true
      const { data } = await activeDetail({ code })
      data.activeTime = [parseTime(data.startTime), parseTime(data.endTime)]
      data.fileList = [{ name: 'bannar', url: data.bannerUrl }]
      await fetchData()
      data.activityTypeIds = handleData(data.activityTypeId)
      data.activityTagIds = handleData(data.activityTagId)
      data.areaList = [data.province, data.city, data.area]
      console.log(data.status)
      state.form = Object.assign({}, data)
      data.signUpDetails.forEach((v) => {
        v.activeName = data.title
      })
      state.signPeopleList = data.signUpDetails
      state.showCasc = true
      console.log(state.signPeopleList)

      state.listLoading = false
      await getTicket()
    }

    onActivated(() => {
      if (proxy.$route.params.id == 'new') {
        state.activeCode = 'new'
        fetchData()
      } else {
        state.activeCode = proxy.$route.params.id
        getActiveDetail(state.activeCode)
      }
    })
    // 图片相关接口
    // 上传海报
    const upBannar = async (e) => {
      console.log(e)
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      console.log(data)
      state.form.bannerUrl = data
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed = (files) => {
      proxy.$baseMessage(
        `当前限制选择 ${proxy.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove = () => {
      state.form.bannerUrl = ''
    }
    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      state.dialogVisible = true
    }

    // 富文本上传图片
    const handleAddImg = async (e) => {
      state.form.description += `<img src="${e}" />`
    }
    const submitInfo = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          console.log(state.form)
          state.form.startTime = state.form.activeTime[0]
          state.form.endTime = state.form.activeTime[1]
          state.form.province = state.form.areaList[0]
          state.form.city = state.form.areaList[1]
          state.form.area = state.form.areaList[2]
          let data = {}
          if (state.activeCode != 'new') {
            data = await editActive(state.form)
            msg = data.msg
          } else {
            data = await addActive(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          console.log(data)
          proxy.$router.push(`/active/editActive/${data.data}`)
        }
      })
    }

    const optionsLevel = {
      lazy: true,
      value: 'name',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { level } = node
        const { code } = node.data
        if (level == 0) {
          const { data } = await getProvinceInfo()
          resolve(data)
        } else if (level == 1) {
          const { data } = await getCityInfo({ code: code })
          resolve(data)
        } else {
          const { data } = await getAreaInfo({ code: code })
          data.forEach((v, i) => {
            v.leaf = level >= 2
          })
          resolve(data)
        }
      },
    }

    // 点击新增票务
    const handleEdit = (row) => {
      if (state.form.id) {
        if (row.id) {
          state.ticketEditRef.showEdit(row)
        } else {
          state.ticketEditRef.showEdit()
        }
      } else {
        proxy.$message.warning('请先保存当前活动基础信息')
      }
    }

    // 获取票据信息
    const getTicket = async () => {
      if (state.form.code) {
        const { data } = await getActiveTicket({
          activityCode: state.form.code,
        })
        state.ticketList = data
        state.signPeopleList.forEach((v) => {
          v.ticketName = data[0].name
        })
      }
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delActiveTicket({ id: row.id })
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await getTicket()
        })
      }
    }

    const handleData = (arr, checkArr) => {
      for (let i in arr) {
        arr[i] = String(arr[i])
      }
      return arr
    }

    // excel 导出
    const handleDownload = () => {
      state.downloadLoading = true
      import('@/utils/excel').then((excel) => {
        //  '证件类型',
        //   '证件号码',
        const tHeader = [
          '活动名称',
          '门票名称',
          '姓名',
          '手机号',
          '报名时间',
          '门票状态',
          '核销时间',
        ]
        //  'idType',
          // 'idNo',
        const filterVal = [
          'activeName',
          'ticketName',
          'name',
          'mobile',
          'registerTime',
          'statusWord',
          'writeOfTime',
        ]
        const list = state.signPeopleList
        list.forEach(v => {
          v.statusWord =  v.status == 'SIGN_UP' ? '已报名' : (v.status == 'PARTICIPATE' ? '已参加' : '已过期')
        })
        console.log(list)
        const data = formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: `${list[0].activeName} - 报名信息`,
          autoWidth: state.autoWidth,
          bookType: state.bookType,
        })
        state.downloadLoading = false
      })
    }
    const formatJson = (filterVal, jsonData) => {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    }

    return {
      ...toRefs(state),
      upBannar,
      fetchData,
      handleAddImg,
      submitInfo,
      handleError,
      handleExceed,
      handleRemove,
      optionsLevel,
      handlePreview,
      handleEdit,
      getTicket,
      handleDate,
      handleDelete,
      handleData,
      handleDownload,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  // height: 85vh;
  // overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}

.contentBox {
  height: 80vh;
  overflow: auto;
}
</style>
